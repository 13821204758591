import _CK from "../utils/cookie";
import _U from "../utils/utilities";

const config = {
    apiKey: "#UE7&CZ[YDABcrGW<JT!t9*e",
};
config.apiHost = () => {
    if (config.isLocal()) {
        return "http://172.22.89.127:3100";
    }
    return "https://api.m2webinar.com";
};
config.isLocal = () => {
    const { hostname } = window.location;
    if (hostname.startsWith("local")) {
        return true;
    } else {
        return false;
    }
};
config.wsHost = (siteEventIdx) => {
    const authors = {
        apikey: config.apiKey,
    };
    if (_CK.isLogin()) {
        authors.authorization = _CK.getAuth();
    }

    if (config.isLocal()) {
        return `ws://172.22.89.127:3300?auth=${_U.encode(authors)}&siteEventIdx=${siteEventIdx}`;
    }
    return `wss://ws.m2webinar.com?auth=${_U.encode(authors)}&siteEventIdx=${siteEventIdx}`;
};
config.google = {
    maps: {
        apiKey: "AIzaSyByU4xgpSzrDltOxZQPCRCrml94ZtiXyow",
    },
};

export default config;
