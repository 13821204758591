import React, { createContext, useState } from "react";

const AppContext = createContext();
const AppProvider = ({ auth: authInProps, iam: iamInProps, cfg: cfgInProps, eventDate: eventDateInProps, children }) => {
	const [auth, setAuth] = useState(authInProps);
	const [iam, setIam] = useState(iamInProps);
	const [cfg, setCfg] = useState(cfgInProps);
	const [eventDate, setEventDate] = useState(eventDateInProps);

	const saveAuth = (auth) => {
		setAuth(auth);
	};
	const saveIam = (iam) => {
		setIam(iam);
	};
	const saveCfg = (cfg) => {
		setCfg(cfg);
	};
	const saveEventDate = (eventDate) => {
		setEventDate(eventDate);
	};
	return <AppContext.Provider value={{ auth, saveAuth, iam, saveIam, cfg, saveCfg, eventDate, saveEventDate }}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
