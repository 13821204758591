import React, { useContext, useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import cx from "classnames";

import { AppContext } from "../../../components/AppProvider";

import { _API, _U, _CK } from "../../../modules/utils";

export const Content = (props) => {
    const { code } = props;
    const { cfg, auth } = useContext(AppContext);

    const { idx: siteIdx } = cfg;
    const { idx: siteEventIdx } = cfg.event;

    const [html, setHtml] = useState("");
    const [files, setFiles] = useState([]);

    const loadData = () => {
        _API.get({
            path: "/v1/site/event/content",
            data: {
                siteIdx,
                siteEventIdx,
                code,
            },
        }).then((res) => {
            const { html, files } = res.data;
            setHtml(html);
            setFiles(files);
        });
    };

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");
        loadData();
    }, [siteIdx, siteEventIdx, code, auth]);

    return (
        <Fragment>
            <ContentWrapper dangerouslySetInnerHTML={{ __html: html }} />
            {_CK.isLogin() && files.length > 0 && (
                <ContentFiles>
                    {files.map((item, i) => (
                        <li key={i}>
                            <a href={item.url} target="_blank">
                                {item.name}
                            </a>
                        </li>
                    ))}
                </ContentFiles>
            )}
        </Fragment>
    );
};

export const ContentWrapper = styled.div`
    img {
        max-width: 100%;
    }
    iframe {
        max-width: 100%;
    }
    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        * {
            color: #3e3e3e !important;
        }
    }
`;

const ContentFiles = styled.ul`
    margin: 1rem 0 0;
    list-style-type: none;
    li {
        margin-bottom: 0.3rem;
    }
    a {
        padding: 0.3rem 1rem;
        display: block;
        color: ${(props) => props.theme.basic.color.primary};
        border: #ffffff solid 1px;
        border-radius: 0.3rem;
        background: #ffffff;
        box-shadow: 0 3px 6px 0 rgba(51, 51, 51, 0.25);
    }
`;

export const Container = styled.div`
    position: relative;
    margin: 0 auto;
    width: ${(props) => props.theme.basic.width}px;

    ${(props) => props.hasLogo && `padding-top:${props.theme.header.height}px;`}

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        width: 100%;
        ${(props) => props.hasMenu && `padding-bottom:${props.theme.menu.height}px;`}
    }
`;

export const Wrapper = styled.div`
    margin: 1rem 0;

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        margin: 1rem;
        padding: 1rem;
        background: #ffffff;
        border-radius: 0.5rem;
    }
`;

export const HeadWrapper = styled.div`
    position: fixed;
    top: 0;
    width: ${(props) => props.theme.basic.width}px;
    height: ${(props) => props.theme.header.height}px;
    background-image: url(${(props) => props.bg?.pc});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 1000;

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        width: 100%;
        background-image: url(${(props) => props.bg?.mobile});
    }

    a {
        display: block;
        width: 100%;
        height: 100%;
    }
`;

export const NavWrapper = styled.div`
    -webkit-appearance: none;

    height: ${(props) => props.theme.nav.height}px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    ${(props) => (props.bg ? `background:${props.bg};` : "background:#ffffff;")}

    ul {
        list-style: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    li {
        height: 3rem;
        display: "inline-block";
        flex: 1;
        text-align: center;
    }

    a {
        display: block;
        line-height: ${(props) => props.theme.nav.height}px;
        width: 100%;
        height: 100%;
        color: ${(props) => (props.font ? props.font : `#333333`)};
    }
    a:hover,
    a.active {
        border-bottom: #333333 solid 3px;
    }

    @media only screen and (min-width: ${(props) => props.theme.responsive.minWidth + 1}px) {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: none;

        li.home {
            display: none;
        }
    }
`;

export const CopyWrapper = styled.div`
    img {
        width: 100%;
        content: url(${(props) => props.img?.pc});
    }

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        img {
            content: url(${(props) => props.img?.mobile});
        }
    }
`;

export const WebinarWrapper = styled.div`
    margin: 0;
`;

export const WebinarVisualWrapper = styled.div`
    img {
        width: 100%;
        content: url(${(props) => props.img?.pc});
    }

    iframe {
        width: 100%;
        height: calc(${(props) => props.theme.basic.width}px * 9.5 / 16);
    }

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        img {
            content: url(${(props) => props.img?.mobile});
        }
        iframe {
            width: 100%;
            height: calc(100vw * 9.5 / 16);
        }
    }
`;

export const WebinarAgendaWrapper = styled.div`
    margin: 1rem 0 0;
    img {
        width: 100%;
        content: url(${(props) => props.img?.pc});
    }

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        img {
            content: url(${(props) => props.img?.mobile});
        }
    }
`;

export const WebinarContent = styled.div`
    position: relative;
    margin: 1rem 0 0;
    display: flex;

    .content {
        margin-top: 0rem;
        flex: 1;
        flex-direction: column;
    }

    .left {
        padding-right: 4rem;
        border-right: #d9d9d9 solid 1px;
    }

    .right {
        padding-left: 4rem;
    }

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        display: block;

        .left {
            padding-right: 0rem;
            border-right: none;
        }

        .right {
            margin: 1rem;
            padding: 1rem;
            border-radius: 0.5rem;
            background: #ffffff;
        }
    }
`;

export const H2 = styled.h2`
    margin: 0 0 1rem;
    font-size: 1.2rem;
    text-align: center;
    color: ${(props) => props.theme.basic.color.primary};
`;

export const InputForm = (props) => {
    const { title, hr, type, value, onChange, name, description } = props;
    return (
        <Fragment>
            <FormControl>
                {title && <Label>{title}</Label>}
                <FormCol>
                    <Input type={type || "text"} value={value || ""} placeholder={props.placeholder} onChange={onChange} name={name} step={props.step} />
                </FormCol>
            </FormControl>
            {description && (
                <FormControl>
                    <FormCol>{description}</FormCol>
                </FormControl>
            )}
            {hr && <HRDash />}
        </Fragment>
    );
};

export const LocationForm = (props) => {
    const { title, hr, value, onChange, name, description, placeholder } = props;

    const handleChange = (index, e) => {
        const v = {
            [name[0]]: index === 0 ? e.target.value : value[0],
            [name[1]]: index === 1 ? e.target.value : value[1],
            [name[2]]: index === 2 ? e.target.value : value[2],
        };
        onChange && onChange(v);
    };

    return (
        <Fragment>
            <FormControl>
                {title && <Label>{title}</Label>}
                <FormCol>
                    <Input type="text" value={value[0] || ""} placeholder={placeholder[0]} onChange={handleChange.bind(this, 0)} name={name[0]} />
                </FormCol>
                <FormCol>
                    <Input type="text" value={value[1] || ""} placeholder={placeholder[1]} onChange={handleChange.bind(this, 1)} name={name[1]} />
                </FormCol>
                <FormCol>
                    <Input type="text" value={value[2] || ""} placeholder={placeholder[2]} onChange={handleChange.bind(this, 2)} name={name[2]} />
                </FormCol>
            </FormControl>
            {description && (
                <FormControl>
                    <FormCol>{description}</FormCol>
                </FormControl>
            )}
            {hr && <HRDash />}
        </Fragment>
    );
};

export const SelectForm = (props) => {
    const { title, name, nullTitle, hr, items, value, onChange } = props;
    return (
        <Fragment>
            <FormControl>
                {title && <Label>{title}</Label>}
                <FormCol>
                    <select name={name} value={value || ""} onChange={onChange} className="">
                        <option value="">{nullTitle}</option>
                        {items.map((item, i) => (
                            <option key={i} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                </FormCol>
            </FormControl>
            {hr && <HRDash />}
        </Fragment>
    );
};

const Button = styled.button.attrs({ type: "button" })`
    ${(props) => (props.type === "SUBMIT" ? `` : ``)}

    flex:1;
    height: 3.125rem;
    font-size: 0.8rem;
    font-weight: normal;
    text-align: center;
    line-height: 3.125rem;

    border-radius: 1.625rem;

    ${(props) =>
        props.primary
            ? `
        color: #ffffff;
        border: #1889ea solid 1px;
        background: #1889ea;    
        box-shadow: 0 3px 6px 0 rgba(24, 137, 234, 0.25);
    `
            : `
        color: #333333;
        border: #ffffff solid 1px;
        background: #ffffff;
        box-shadow: 0 3px 6px 0 rgba(51, 51, 51, 0.25);
    `}
`;

export const ButtonForm = (props) => {
    const { title, onClick, type, primary } = props;

    return (
        <Button primary={primary} onClick={onClick} type={type}>
            {title}
        </Button>
    );
};

export const ButtonFormGroup = styled.div`
    display: flex;
    button:nth-child(odd) {
        margin-right: 0.5rem;
    }
    button:nth-child(even) {
        margin-left: 0.5rem;
    }
`;

export const InputViewForm = (props) => {
    const { title, hr, value, description } = props;
    return (
        <Fragment>
            <FormControl>
                {title && <Label>{title}</Label>}
                <FormCol description>{value}</FormCol>
            </FormControl>
            {description && (
                <FormControl>
                    <FormCol>{description}</FormCol>
                </FormControl>
            )}
            {hr && <HRDash />}
        </Fragment>
    );
};

const Input = styled.input.attrs((props) => {
    return {
        type: props.type || "text",
        step: props.step || "",
    };
})`
    font-size: 1rem;
    border: #dedede solid 1px;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    width: 100%;
    color: #333333;
`;

const FormCol = styled.div`
    flex: 1;
    ${(props) =>
        props.description &&
        `
            font-size: 1rem;
            padding-top: 0.8rem;
        `}
`;
const Label = styled.label`
    padding: 0.8rem 1rem 0 0;
    flex-basis: 10rem;
    font-size: 1rem;
    text-align: right;
    color: #333333;

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        text-align: left;
        margin-bottom: 0.5rem;
    }
`;
export const FormControl = styled.div`
    display: flex;
    margin-bottom: 0.8rem;

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        display: block;
    }
`;
export const HRDash = styled.hr`
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
`;

const Title = styled.h3`
    margin-bottom: 1rem;
    color: ${(props) => (props.font ? props.font : `#3e3e3e`)};
    font-size: 1.5rem;
    line-height: 2rem;
`;

const SessionWrapper = styled.div`
    margin-top: 1rem;

    li {
        cursor: pointer;
    }

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        padding: 0 1rem;
    }
`;

export const WebinarSessionList = (props) => {
    const { cfg } = useContext(AppContext);
    const { sessions } = props.location;
    const { event } = cfg;

    if (sessions?.length == 0) {
        return "";
    }

    const getStripHtml = (value) => {
        if (!value) {
            return "";
        }

        const regex = /(<([^>]+)>)/gi;
        return " - " + value?.replace(regex, "");
    };

    return (
        <SessionWrapper>
            <Title font={event?.uiFontColor}>AGENDA</Title>
            <ul className="list-group">
                {sessions?.map((item, i) => (
                    <li key={i} className={cx({ "list-group-item": true, "list-group-item-action": true, "list-group-item-primary": props.selectVodTime === item.vodTime })} onClick={props.setSelectVodTime.bind(this, item.vodTime)}>
                        {item.vodTime} {item.title} {getStripHtml(item.speaker)}
                    </li>
                ))}
            </ul>
        </SessionWrapper>
    );
};
