import React, { useEffect, useState } from "react";
import GlobalStyles, { ThemeProvider, Theme } from "./components/GlobalStyles";
import qs from "query-string";

// App Provider
import { AppProvider } from "./components/AppProvider";

// Utils
import { _CK, _API } from "./modules/utils";

// UI
import { Loading } from "./components/UI/Loading";

// Router
import Views from "./views";

export default () => {
    const [loaded, setLoaded] = useState(false);
    const [auth, setAuth] = useState("");
    const [iam, setIam] = useState(null);
    const [cfg, setCfg] = useState(null);
    const [isAuthLogin, setIsAuthLogin] = useState(false);
    const [eventDate, setEventDate] = useState("");

    const preLoad = async () => {
        try {
            let { hostname } = window.location;
            /* 디버그용 hostname 변조
            hostname = "local.m2webinar.com";
            /**/

            let eventDate = null;
            if (window.location.search) {
                const parsedQs = qs.parse(window.location.search);
                eventDate = parsedQs.eventDate;
                setEventDate(eventDate);
            }

            if (hostname.split(".").length > 2) {
                let data = await _API.get({ path: "/v1/site", data: { domain: hostname, eventDate } });
                const { data: cfg } = data;

                setCfg(cfg);
                _CK.setCFCookies(cfg);

                document.title = cfg.event ? `[${cfg.title}] ${cfg.event.title}` : cfg.title;

                const parsedQs = qs.parse(window.location.search);

                if (parsedQs.ac) {
                    setIsAuthLogin(true);
                    await authCodeLogin(parsedQs.ac, cfg);
                }
            }

            if (_CK.isLogin()) {
                const data = await _API.iam();
                const { data: iam } = data;
                if (iam.status === "Y") {
                    setAuth(_CK.getAuth());
                    setIam(iam);
                } else {
                    _CK.clearAuth();
                }
            }
            setLoaded(true);
            // console.log(":::: App Pre Loaded ::::");
        } catch (e) {
            console.log("APP PreLoad Error", e);
            _CK.clearAuth();
            //document.location.href = "/";
        }
    };

    const authCodeLogin = (authCode, cfg) => {
        _API.post({
            path: "/v1/site/event/login/authCode",
            data: {
                siteIdx: cfg.idx,
                siteEventIdx: cfg.event?.idx,
                authCode,
            },
        })
            .then((res) => {
                const { accessKey: auth } = res.data;
                _CK.setAuth(auth);
                document.location.href = "/";
            })
            .catch((e) => {
                _CK.clearAuth();
                document.location.href = "/";
            });
    };

    useEffect(() => {
        preLoad();
    }, []);

    if (isAuthLogin) {
        return "";
    }

    if (!loaded) {
        return (
            <ThemeProvider theme={Theme}>
                <Loading />
                <GlobalStyles />
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={Theme}>
            <AppProvider auth={auth} iam={iam} cfg={cfg} eventDate={eventDate}>
                <Views />
                <GlobalStyles />
            </AppProvider>
        </ThemeProvider>
    );
};
