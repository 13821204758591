import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import cx from "classnames";

import { AppContext } from "../../../components/AppProvider";

import { Wrapper as Container, Content, InputForm, SelectForm, ButtonForm, InputViewForm, ButtonFormGroup, HRDash, H2, CheckboxForm } from "./Components";
import { _API, _U, _CK } from "../../../modules/utils";

export default (props) => {
    const { history } = props;
    const { cfg } = useContext(AppContext);
    const { event } = cfg;
    const { cr, crAgree, crAgreeTop } = event;

    const [item, setItem] = useState({});
    const [pathItem, setPathItem] = useState([]);
    const [teamWareItem, setTeamWareItem] = useState([]);
    const [teamWareColorItem, setTeamWareColorItem] = useState([]);
    const [singleSelectItem, setSingleSelectItem] = useState([]);
    const [multiSelectItem, setMultiSelectItem] = useState([]);
    const [agree, setAgree] = useState([]);

    useEffect(() => {
        if (event) {
            if (event?.preRegStartDate) {
                if (moment() <= moment(event?.preRegStartDate)) {
                    swal("사전등록 기간이 아닙니다.").then(() => {
                        history.push("/");
                    });
                    return;
                }
            }

            if (event?.preRegEndDate) {
                if (moment() >= moment(event?.preRegEndDate)) {
                    swal("사전등록이 종료 되었습니다.").then(() => {
                        history.push("/");
                    });
                    return;
                }
            }
        }
    }, [event]);

    useEffect(() => {
        if (props.item) {
            let tmp = [];
            if (cr?.useMultiSelect === "Y" && props.item?.multiSelect) {
                tmp = props.item.multiSelect.split(",");
            }

            setItem({ ...props.item, multiSelect: tmp });
        }
    }, [props.item]);

    useEffect(() => {
        if (!cr) {
            swal("설정된 사전등록 항목이 없습니다.").then(() => {
                props.history?.push("/");
                return;
            });
        }

        if (cr?.usePath === "Y" && cr?.pathValue) {
            const pathValue = cr?.pathValue.split(",");
            const _pathTmp = [];

            for (let i in pathValue) {
                _pathTmp.push({ label: pathValue[i], value: pathValue[i] });
            }

            _pathTmp.push({ label: "기타", value: "기타" });

            setPathItem(_pathTmp);
        }

        if (cr?.useSingleSelect === "Y" && cr?.singleSelectValue) {
            const singleSelectValue = cr?.singleSelectValue.split(",");
            const _singleTmp = [];

            for (let i in singleSelectValue) {
                _singleTmp.push({ label: singleSelectValue[i], value: singleSelectValue[i] });
            }

            //_singleTmp.push({ label: "기타", value: "기타" });

            setSingleSelectItem(_singleTmp);
        }

        if (cr?.useMultiSelect === "Y" && cr?.multiSelectValue) {
            const multiSelectValue = cr?.multiSelectValue.split(",");
            const _multiTmp = [];

            for (let i in multiSelectValue) {
                _multiTmp.push({ label: multiSelectValue[i], value: multiSelectValue[i] });
            }

            setMultiSelectItem(_multiTmp);
        }

        if (cr?.useTeamWare === "Y" && cr?.teamWareValue) {
            const teamValue = cr?.teamWareValue.split(",");
            const _teamWareTmp = [];

            for (let i in teamValue) {
                _teamWareTmp.push({ label: teamValue[i], value: teamValue[i] });
            }

            setTeamWareItem(_teamWareTmp);
        }

        if (cr?.useTeamWare === "Y" && cr?.teamWareColor) {
            const colorValue = cr?.teamWareColor.split(",");
            const _teamWareColorTmp = [];

            for (let i in colorValue) {
                _teamWareColorTmp.push({ label: colorValue[i], value: colorValue[i] });
            }

            setTeamWareColorItem(_teamWareColorTmp);
        }
    }, []);

    const onSetItem = (e) => {
        const target = e.target;
        let value = target.value;

        if (target.name === "mobile") {
            value = value.replace(/-/g, "");
        }

        if (target.name === "multiSelect") {
            const data = JSON.parse(JSON.stringify(item));
            let multiSelect = data?.multiSelect || [];

            if (e.target.checked) {
                multiSelect.push(value);
            } else {
                multiSelect = multiSelect.filter((ms) => ms !== value);
            }

            setItem((o) => {
                return { ...o, multiSelect };
            });
        } else {
            setItem((o) => {
                return { ...o, [target.name]: value };
            });
        }
    };

    const onSave = () => {
        if (cr?.useName === "Y" && cr?.nameRequire === "Y" && !item.name) {
            swal(`${cr?.nameText || "성명"}을(를) 입력하세요.`);
            return;
        }
        if (cr?.useEmail === "Y" && cr?.emailRequire === "Y" && !item.email) {
            swal(`${cr?.emailText || "이메일"}을(를) 입력하세요.`);
            return;
        }
        if (cr?.useMobile === "Y" && cr?.mobileRequire === "Y" && !item.mobile) {
            swal(`${cr?.mobileText || "휴대전화번호"}을(를) 입력하세요.`);
            return;
        }
        if (cr?.useCompany === "Y" && cr?.companyRequire === "Y" && !item.company) {
            swal(`${cr?.companyText || "소속"}을(를) 입력하세요.`);
            return;
        }
        if (cr?.useDepartment === "Y" && cr?.departmentRequire === "Y" && !item.department) {
            swal(`${cr?.departmentText || "부서"}을(를) 입력하세요.`);
            return;
        }
        if (cr?.usePosition === "Y" && cr?.positionRequire === "Y" && !item.position) {
            swal(`${cr?.positionText || "직책"}을(를) 입력하세요.`);
            return;
        }
        if (cr?.useManager === "Y" && cr?.managerRequire === "Y" && !item.manager) {
            swal(`${cr?.managerText || "담당자"}을(를) 입력하세요.`);
            return;
        }
        if (cr?.usePath === "Y") {
            if (cr?.pathRequire === "Y" && !item.path) {
                swal(`${cr?.pathText || "등록경로"}을(를) 선택하세요.`);
                return;
            }
            if (item.path === "기타" && !item?.pathText) {
                swal(`${cr?.pathText || "등록경로"}을(를) 입력하세요.`);
                return;
            }
        }
        if (cr?.useTeamWare === "Y" && cr?.teamWareRequire === "Y") {
            if (!item.teamWareSize) {
                swal(`${cr?.teamWareText || "팀복사이즈"}을(를) 선택하세요.`);
                return;
            }
            if (teamWareColorItem?.length > 0 && !item.teamWareColor) {
                swal(`색상을 선택하세요.`);
                return;
            }
        }

        if (cr?.useSingleSelect === "Y") {
            if (cr?.singleSelectRequire === "Y" && !item.singleSelect) {
                swal(`${cr?.singleSelectText || "단일 선택 항목"}을(를) 선택하세요.`);
                return;
            }
            if (item.singleSelect === "기타" && !item?.singleSelectText) {
                swal(`${cr?.singleSelectText || "단일 선택 항목"}을(를) 입력하세요.`);
                return;
            }
        }

        if (cr?.useMultiSelect === "Y") {
            if (cr?.multiSelectRequire === "Y" && (!item.multiSelect || item?.multiSelect.length === 0)) {
                swal(`${cr?.multiSelectText || "체크박스 선택 항목"}을(를) 선택하세요.`);
                return;
            }
        }
        if (event?.useLoginPassword === "Y") {
            if (!item?.passwd) {
                swal(`비밀번호를 입력하세요.`);
                return;
            }
            if (!item?.passwdRe) {
                swal(`비밀번호 확인을 입력하세요.`);
                return;
            }

            if (item?.passwdRe !== item?.passwd) {
                swal(`비밀번호가 일치하지 않습니다.`);
                return;
            }
        }

        if (crAgree && crAgree?.length > 0) {
            for (let i in crAgree) {
                const a = crAgree[i];

                const fAgree = agree.find((d) => d.idx === a.idx);

                if (!fAgree) {
                    swal(`'${a.title}' 항목에 동의 여부를 선택해 주세요`);
                    return;
                }

                if (a.isRequire === "Y" && fAgree?.status !== "Y") {
                    swal(`'${a.title}' 항목은 필수 동의 하셔야 합니다.`);
                    return;
                }
            }
        } else {
            if (item.agree !== "Y") {
                swal("개인정보 활용에 동의하여 주세요.");
                return false;
            }
        }

        const data = { ...item, siteIdx: cfg.idx, siteEventIdx: cfg.event?.idx, agree };

        _API.post({
            path: "/v1/site/event/registration",
            data: data,
        }).then((res) => {
            swal({ title: "알림", text: item.idx ? `사전등록 수정이 완료 되었습니다.` : `사전등록이 완료 되었습니다.` }).then(() => {
                props.onComplete && props.onComplete();
            });
        });
    };

    const onBack = () => {
        props.onCancel && props.onCancel();
    };

    const onAgreeItem = (item, e) => {
        const tmp = JSON.parse(JSON.stringify(agree));

        const isExsits = tmp.findIndex((d) => parseInt(d.idx) === parseInt(item.idx));

        if (isExsits > -1) {
            tmp[isExsits].status = e.target.value;
        } else {
            tmp.push({ idx: item.idx, status: e.target.value });
        }

        setAgree(tmp);
    };

    return (
        <>
            {cr?.useName === "Y" && <InputForm title={cr?.nameText || "성명"} name="name" value={item?.name || ""} onChange={onSetItem} required={cr?.nameRequire === "Y"} />}
            {cr?.useEmail === "Y" && <InputForm title={cr?.emailText || "이메일"} name="email" value={item?.email || ""} onChange={onSetItem} required={cr?.emailRequire === "Y"} />}
            {cr?.useMobile === "Y" && <InputForm title={cr?.mobileText || "휴대전화번호"} name="mobile" value={item?.mobile || ""} onChange={onSetItem} required={cr?.mobileRequire === "Y"} />}
            {cr?.useCompany === "Y" && <InputForm title={cr?.companyText || "소속"} name="company" value={item?.company || ""} onChange={onSetItem} required={cr?.companyRequire === "Y"} />}
            {cr?.useDepartment === "Y" && (
                <InputForm title={cr?.departmentText || "부서"} name="department" value={item?.department || ""} onChange={onSetItem} required={cr?.departmentRequire === "Y"} />
            )}
            {cr?.usePosition === "Y" && <InputForm title={cr?.positionText || "직책"} name="position" value={item?.position || ""} onChange={onSetItem} required={cr?.positionRequire === "Y"} />}
            {cr?.useManager === "Y" && <InputForm title={cr?.managerText || "담당자"} name="manager" value={item?.manager || ""} onChange={onSetItem} required={cr?.managerRequire === "Y"} />}
            {cr?.usePath === "Y" && (
                <>
                    <SelectForm
                        title={cr?.pathText || "등록경로"}
                        name="path"
                        items={pathItem}
                        value={item?.path}
                        onChange={onSetItem}
                        nullTitle={`${cr?.pathText || "등록경로"} 선택`}
                        required={cr?.pathRequire === "Y"}
                    />
                    {item?.path === "기타" && <InputForm title={" "} name="pathText" value={item?.pathText || ""} onChange={onSetItem} placeholder={`${cr?.pathText || "등록경로"} 입력`} />}
                </>
            )}
            {cr?.useTeamWare === "Y" && (
                <>
                    <SelectForm
                        title={cr?.teamWareText || "팀복 사이즈"}
                        name="teamWareSize"
                        items={teamWareItem}
                        value={item?.teamWareSize}
                        onChange={onSetItem}
                        nullTitle={`${cr?.teamWareText || "팀복사이즈"} 선택`}
                        required={cr?.teamWareRequire === "Y"}
                    />
                    {teamWareColorItem?.length > 0 && (
                        <SelectForm
                            title={"색상"}
                            name="teamWareColor"
                            items={teamWareColorItem}
                            value={item?.teamWareColor}
                            onChange={onSetItem}
                            nullTitle={`색상 선택`}
                            required={cr?.teamWareRequire === "Y"}
                        />
                    )}
                </>
            )}

            {cr?.useSingleSelect === "Y" && (
                <>
                    <SelectForm
                        title={cr?.singleSelectText || "단일 항목"}
                        name="singleSelect"
                        items={singleSelectItem}
                        value={item?.singleSelect}
                        onChange={onSetItem}
                        nullTitle={`${cr?.singleSelectText || "단일 항목"} 선택`}
                        required={cr?.singleSelectRequire === "Y"}
                    />
                    {item?.singleSelect === "기타" && (
                        <InputForm title={" "} name="singleSelectText" value={item?.singleSelectText || ""} onChange={onSetItem} placeholder={`${cr?.singleSelectText || "단일 항목"} 입력`} />
                    )}
                </>
            )}

            {cr?.useMultiSelect === "Y" && (
                <>
                    <CheckboxForm
                        title={cr?.multiSelectText || "체크박스 항목"}
                        name="multiSelect"
                        items={multiSelectItem}
                        value={item?.multiSelect}
                        onChange={onSetItem}
                        nullTitle={`${cr?.multiSelectText || "체크박스 항목"} 선택`}
                        required={cr?.multiSelectRequire === "Y"}
                    />
                </>
            )}

            {event?.useLoginPassword === "Y" && (
                <>
                    <InputForm title="비밀번호" name="passwd" type="password" value={item.passwd || ""} onChange={onSetItem} required={true} />
                    <InputForm title="비밀번호 확인" name="passwdRe" type="password" value={item.passwdRe || ""} onChange={onSetItem} required={true} description={event?.passwordDesc} />
                </>
            )}
            <HRDash />

            {crAgree && crAgree?.length > 0 ? (
                <>
                    <Agreement crAgree={crAgreeTop} />
                    <Agreement crAgree={crAgree} onAgreeItem={onAgreeItem} agree={agree} />
                </>
            ) : (
                <>
                    <Content code="registration" />
                    <HRDash />
                    <div style={{ textAlign: "center" }}>
                        <label>
                            <input type="radio" name="agree" value="Y" onChange={onSetItem} /> 개인정보 수집 및 이용에 동의함
                        </label>
                        <label style={{ marginLeft: "1rem" }}>
                            <input type="radio" name="agree" value="N" onChange={onSetItem} />
                            동의안함
                        </label>
                    </div>
                    <div style={{ fontSize: "0.8rem", fontWeight: "normal", marginTop: "0.8rem", lineHeight: "1rem" }}>
                        상단에 게시된 회사의 개인정보수집 및 이용에 관한 설명을 모두 이해하였고, 이에 동의하시면 "동의함"에 체크하여 주시기 바랍니다.
                    </div>
                    <HRDash />
                </>
            )}
            <ButtonFormGroup>
                <ButtonForm primary title="확인" type="SUBMIT" onClick={onSave} />
                <ButtonForm title="취소" type="RESET" onClick={onBack} />
            </ButtonFormGroup>
        </>
    );
};

const Agreement = (props) => {
    const { crAgree, onAgreeItem, agree } = props;

    return (
        <AgreeWrapper>
            {crAgree?.map((item, index) => (
                <React.Fragment key={index}>
                    {item?.title && (
                        <h2>
                            {item.title}({item.isRequire === "Y" ? `필수` : `선택`})
                        </h2>
                    )}
                    <div className="content" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                    {item.isTop !== "Y" && (
                        <>
                            <HRDash />
                            <div style={{ textAlign: "center" }}>
                                <label>
                                    <input type="radio" name={`agree${index}`} value="Y" onChange={(e) => onAgreeItem(item, e)} />
                                    &nbsp;&nbsp;동의함
                                </label>
                                <label style={{ marginLeft: "1rem" }}>
                                    <input type="radio" name={`agree${index}`} value="N" onChange={(e) => onAgreeItem(item, e)} />
                                    &nbsp;&nbsp; 동의안함
                                </label>
                            </div>
                        </>
                    )}
                    <HRDash />
                </React.Fragment>
            ))}
        </AgreeWrapper>
    );
};

const AgreeWrapper = styled.div`
    margin-bottom: 20px;
    > h2 {
        font-size: 18px;
        padding-bottom: 20px;
    }

    .content {
        font-weight: 400;
        width: 100%;
        line-height: 24px;
        img {
            max-width: 100%;
        }
    }
`;

export const CustomRegCheckView = (props) => {
    const { code, history } = props;
    const { cfg } = useContext(AppContext);
    const { event } = cfg;
    const { cr } = event;

    const [item, setItem] = useState({});

    const pointRef = useRef(null);
    const scrollToBottom = () => {
        pointRef?.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(scrollToBottom, []);

    const onSetItem = (e) => {
        const target = e.target;
        setItem((o) => {
            return { ...o, [target.name]: target.value };
        });
    };

    const onBack = () => {
        history?.push("/");
    };

    const onSave = () => {
        if (!item.name) {
            swal("성명을 입력하세요.");
            return false;
        }
        if (event?.loginType === "EMAIL" && !item.email) {
            swal("이메일을 입력하세요.");
            return false;
        }
        if (event?.loginType === "MOBILE" && !item.mobile) {
            swal("휴대폰을 입력하세요.");
            return false;
        }

        // if (event?.useLoginPassword === "Y" && !item.passwd) {
        // 	swal("비밀번호를 입력하세요.");
        // 	return false;
        // }

        const data = { ...item, siteIdx: cfg.idx, siteEventIdx: cfg.event?.idx };

        _API.get({
            path: "/v1/site/event/registration/check",
            data: data,
        }).then((res) => {
            if (res.error === 0) {
                _U.setSession("_REGISTRATION_CHECK", res.data);
                history?.push("/registration/view");
            } else {
                swal(res.message);
            }
        });
    };

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");
    }, []);

    return (
        <Container>
            <Wrapper ref={pointRef}>
                <H2>사전등록 조회</H2>
                <InputForm title="성명" name="name" value={item.name || ""} onChange={onSetItem} placeholder="사전등록시 입력한 성명을 입력하세요." />
                {event?.loginType === "EMAIL" && <InputForm title="이메일" name="email" value={item.email || ""} onChange={onSetItem} placeholder="사전등록시 입력한 이메일을 입력하세요." />}
                {event?.loginType === "MOBILE" && (
                    <InputForm title="휴대폰번호" name="mobile" value={item.mobile || ""} onChange={onSetItem} placeholder="사전등록시 입력한 휴대폰번호를 입력하세요." />
                )}
                {/* {event?.useLoginPassword === "Y" && (
					<>
						<InputForm title="비밀번호" name="passwd" type="password" value={item.passwd} onChange={onSetItem} placeholder="사전등록시 입력한 비밀번호를 입력해 주세요" />
					</>
				)} */}
                <HRDash />
                <ButtonFormGroup>
                    <ButtonForm primary title="확인" type="SUBMIT" onClick={onSave} />
                    <ButtonForm title="취소" type="RESET" onClick={onBack} />
                </ButtonFormGroup>
            </Wrapper>
        </Container>
    );
};

export const CustomRegDetailView = (props) => {
    const { code, history } = props;
    const { cfg } = useContext(AppContext);

    const { event } = cfg;
    const { cr } = event;

    const [idx, setIdx] = useState(null);
    const [item, setItem] = useState({});

    const sessionCode = "_REGISTRATION_CHECK";

    const pointRef = useRef(null);
    const scrollToBottom = () => {
        pointRef?.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(scrollToBottom, []);

    useEffect(() => {
        const session = _U.getSession(sessionCode);
        if (!session) {
            onBack();
            return;
        }

        if (session.code !== sessionCode || !session || !session.idx) {
            onBack();
            return;
        }

        setIdx(session.idx);
    }, []);

    const loadData = () => {
        if (idx) {
            const data = { idx: idx, siteIdx: cfg.idx, siteEventIdx: cfg.event?.idx };

            _API.get({
                path: "/v1/site/event/registration/item",
                data: data,
            }).then((res) => {
                if (res.error === 0) {
                    setItem(res.data);
                } else {
                    swal(res.message);
                }
            });
        }
    };

    useEffect(() => {
        _U.postMessage("LOCATION-CHANGED");

        loadData();
    }, [idx]);

    const onBack = () => {
        history?.push("/registration/check");
    };

    return (
        <Container>
            <Wrapper ref={pointRef}>
                <H2>사전등록 내역</H2>
                {cr?.useName === "Y" && <InputViewForm title={cr?.nameText || "성명"} value={item?.name} />}
                {cr?.useEmail === "Y" && <InputViewForm title={cr?.emailText || "이메일"} value={item?.email} />}
                {cr?.useMobile === "Y" && <InputViewForm title={cr?.mobileText || "휴대전화번호"} value={item?.mobile} />}
                {cr?.useCompany === "Y" && <InputViewForm title={cr?.companyText || "소속"} value={item?.company} />}
                {cr?.useDepartment === "Y" && <InputViewForm title={cr?.departmentText || "부서"} value={item?.department} />}
                {cr?.usePosition === "Y" && <InputViewForm title={cr?.positionText || "직책"} value={item?.position} />}
                {cr?.useManager === "Y" && <InputViewForm title={cr?.managerText || "담당자"} value={item?.manager} />}
                {cr?.usePath === "Y" && <InputViewForm title={cr?.pathText || "등록경로"} value={item?.path === "기타" ? item?.pathText : item?.path} />}
                {cr?.useTeamWare === "Y" && (
                    <>
                        <InputViewForm title={cr?.teamWareText || "팀복사이즈"} value={item?.teamWareSize} />
                        {item?.teamWareColor && <InputViewForm title={"색상"} value={item?.teamWareColor} />}
                    </>
                )}
                {cr?.useSingleSelect === "Y" && <InputViewForm title={cr?.singleSelectText || "단일 선택항목"} value={item?.singleSelect === "기타" ? item?.singleSelectText : item?.singleSelect} />}
                {cr?.useMultiSelect === "Y" && <InputViewForm title={cr?.multiSelectText || "체크박스 항목"} value={item?.multiSelect} />}
                <HRDash />
                <ButtonFormGroup>
                    <ButtonForm
                        title="수정"
                        type="SUBMIT"
                        onClick={() => {
                            _U.setSession("_REGISTRATION_MODIFY", { code: "_REGISTRATION_MODIFY", idx: idx });
                            history?.push("/registration/modify");
                        }}
                        primary
                    />
                    <ButtonForm title="취소" type="RESET" onClick={onBack} />
                </ButtonFormGroup>
            </Wrapper>
        </Container>
    );
};

const Wrapper = styled.div`
    width: 70%;
    margin: 1rem auto;
    padding: 1rem;
    background: #ffffff;
    border-radius: 0.5rem;

    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        width: 100%;
        margin: 0rem;
        padding: 0rem;
        border-radius: none;
    }
`;
