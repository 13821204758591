import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import moment from "moment";
import cx from "classnames";

import { AppContext } from "../../../components/AppProvider";

import { Wrapper as Container, Content, InputForm, SelectForm, ButtonForm, InputViewForm, ButtonFormGroup, HRDash, H2 } from "./Components";
import { _API, _U, _CK } from "../../../modules/utils";

export default (props) => {
	const { cfg } = useContext(AppContext);
	const { event } = cfg;
	const { cr } = event;

	const [item, setItem] = useState({});
	const [pathItem, setPathItem] = useState([]);
	const [teamWareItem, setTeamWareItem] = useState([]);
	const [teamWareColorItem, setTeamWareColorItem] = useState([]);

	useEffect(() => {
		if (props.item) {
			setItem(props.item);
		}
	}, [props.item]);

	useEffect(() => {
		if (!cr) {
			swal("설정된 사전등록 항목이 없습니다.").then(() => {
				props.history?.push("/");
				return;
			});
		}

		if (cr?.usePath === "Y" && cr?.pathValue) {
			const pathValue = cr?.pathValue.split(",");
			const _pathTmp = [];

			for (let i in pathValue) {
				_pathTmp.push({ label: pathValue[i], value: pathValue[i] });
			}

			_pathTmp.push({ label: "기타", value: "기타" });

			setPathItem(_pathTmp);
		}

		if (cr?.useTeamWare === "Y" && cr?.teamWareValue) {
			const teamValue = cr?.teamWareValue.split(",");
			const _teamWareTmp = [];

			for (let i in teamValue) {
				_teamWareTmp.push({ label: teamValue[i], value: teamValue[i] });
			}

			setTeamWareItem(_teamWareTmp);
		}

		if (cr?.useTeamWare === "Y" && cr?.teamWareColor) {
			const colorValue = cr?.teamWareColor.split(",");
			const _teamWareColorTmp = [];

			for (let i in colorValue) {
				_teamWareColorTmp.push({ label: colorValue[i], value: colorValue[i] });
			}

			setTeamWareColorItem(_teamWareColorTmp);
		}
	}, []);

	const onSetItem = (e) => {
		const target = e.target;
		let value = target.value;

		if (target.name === "mobile") {
			value = value.replace(/-/g, "");
		}

		setItem((o) => {
			return { ...o, [target.name]: value };
		});
	};

	const onSave = () => {
		if (cr?.useName === "Y" && cr?.nameRequire === "Y" && !item.name) {
			swal(`${cr?.nameText || "성명"}을(를) 입력하세요.`);
			return;
		}
		if (cr?.useEmail === "Y" && cr?.emailRequire === "Y" && !item.email) {
			swal(`${cr?.emailText || "이메일"}을(를) 입력하세요.`);
			return;
		}
		if (cr?.useMobile === "Y" && cr?.mobileRequire === "Y" && !item.mobile) {
			swal(`${cr?.mobileText || "휴대전화번호"}을(를) 입력하세요.`);
			return;
		}
		if (cr?.useCompany === "Y" && cr?.companyRequire === "Y" && !item.company) {
			swal(`${cr?.companyText || "소속"}을(를) 입력하세요.`);
			return;
		}
		if (cr?.useDepartment === "Y" && cr?.departmentRequire === "Y" && !item.department) {
			swal(`${cr?.departmentText || "부서"}을(를) 입력하세요.`);
			return;
		}
		if (cr?.usePosition === "Y" && cr?.positionRequire === "Y" && !item.position) {
			swal(`${cr?.positionText || "직책"}을(를) 입력하세요.`);
			return;
		}
		if (cr?.useManager === "Y" && cr?.managerRequire === "Y" && !item.manager) {
			swal(`${cr?.managerText || "담당자"}을(를) 입력하세요.`);
			return;
		}
		if (cr?.usePath === "Y") {
			if (cr?.pathRequire === "Y" && !item.path) {
				swal(`${cr?.pathText || "등록경로"}을(를) 선택하세요.`);
				return;
			}
			if (item.path === "기타" && !item?.pathText) {
				swal(`${cr?.pathText || "등록경로"}을(를) 입력하세요.`);
				return;
			}
		}
		if (cr?.useTeamWare === "Y" && cr?.teamWareRequire === "Y") {
			if (!item.teamWareSize) {
				swal(`${cr?.teamWareText || "팀복사이즈"}을(를) 선택하세요.`);
				return;
			}
			if (teamWareColorItem?.length > 0 && !item.teamWareColor) {
				swal(`색상을 선택하세요.`);
				return;
			}
		}
		if (event?.useLoginPassword === "Y") {
			if (!item?.passwd) {
				swal(`비밀번호를 입력하세요.`);
				return;
			}
			if (!item?.passwdRe) {
				swal(`비밀번호 확인을 입력하세요.`);
				return;
			}

			if (item?.passwdRe !== item?.passwd) {
				swal(`비밀번호가 일치하지 않습니다.`);
				return;
			}
		}

		if (item.agree !== "Y") {
			swal("개인정보 활용에 동의하여 주세요.");
			return false;
		}

		if (!item.agree2) {
			swal("제3자 제공에 대한 동의 여부를 선택해 주세요.");
			return false;
		}

		if (!item.agree3) {
			swal("마케팅 활용 개인정보 수집·이용 동의 여부를 선택해 주세요.");
			return false;
		}

		const data = { ...item, siteIdx: cfg.idx, siteEventIdx: cfg.event?.idx };

		_API
			.post({
				path: "/v1/site/event/registration",
				data: data,
			})
			.then((res) => {
				swal({ title: "알림", text: item.idx ? `사전등록 수정이 완료 되었습니다.` : `사전등록이 완료 되었습니다.` }).then(() => {
					props.onComplete && props.onComplete();
				});
			});
	};

	const onBack = () => {
		props.onCancel && props.onCancel();
	};

	return (
		<>
			{cr?.useName === "Y" && <InputForm title={cr?.nameText || "성명"} name="name" value={item?.name || ""} onChange={onSetItem} required={cr?.nameRequire === "Y"} />}
			{cr?.useEmail === "Y" && <InputForm title={cr?.emailText || "이메일"} name="email" value={item?.email || ""} onChange={onSetItem} required={cr?.emailRequire === "Y"} />}
			{cr?.useMobile === "Y" && (
				<InputForm title={cr?.mobileText || "휴대전화번호"} name="mobile" value={item?.mobile || ""} onChange={onSetItem} required={cr?.mobileRequire === "Y"} />
			)}
			{cr?.useCompany === "Y" && (
				<InputForm title={cr?.companyText || "소속"} name="company" value={item?.company || ""} onChange={onSetItem} required={cr?.companyRequire === "Y"} />
			)}
			{cr?.useDepartment === "Y" && (
				<InputForm title={cr?.departmentText || "부서"} name="department" value={item?.department || ""} onChange={onSetItem} required={cr?.departmentRequire === "Y"} />
			)}
			{cr?.usePosition === "Y" && (
				<InputForm title={cr?.positionText || "직책"} name="position" value={item?.position || ""} onChange={onSetItem} required={cr?.positionRequire === "Y"} />
			)}
			{cr?.useManager === "Y" && (
				<InputForm title={cr?.managerText || "담당자"} name="manager" value={item?.manager || ""} onChange={onSetItem} required={cr?.managerRequire === "Y"} />
			)}
			{cr?.usePath === "Y" && (
				<>
					<SelectForm
						title={cr?.pathText || "등록경로"}
						name="path"
						items={pathItem}
						value={item?.path}
						onChange={onSetItem}
						nullTitle={`${cr?.pathText || "등록경로"} 선택`}
						required={cr?.pathRequire === "Y"}
					/>
					{item?.path === "기타" && <InputForm title={" "} name="pathText" value={item?.pathText || ""} onChange={onSetItem} placeholder={`${cr?.pathText || "등록경로"} 입력`} />}
				</>
			)}
			{cr?.useTeamWare === "Y" && (
				<>
					<SelectForm
						title={cr?.teamWareText || "팀복 사이즈"}
						name="teamWareSize"
						items={teamWareItem}
						value={item?.teamWareSize}
						onChange={onSetItem}
						nullTitle={`${cr?.teamWareText || "팀복사이즈"} 선택`}
						required={cr?.teamWareRequire === "Y"}
					/>
					{teamWareColorItem?.length > 0 && (
						<SelectForm
							title={"색상"}
							name="teamWareColor"
							items={teamWareColorItem}
							value={item?.teamWareColor}
							onChange={onSetItem}
							nullTitle={`색상 선택`}
							required={cr?.teamWareRequire === "Y"}
						/>
					)}
				</>
			)}
			{event?.useLoginPassword === "Y" && (
				<>
					<InputForm title="비밀번호" name="passwd" type="password" value={item.passwd || ""} onChange={onSetItem} required={true} />
					<InputForm title="비밀번호 확인" name="passwdRe" type="password" value={item.passwdRe || ""} onChange={onSetItem} required={true} />
				</>
			)}
			<HRDash />

			{/* <Content code="registration" /> */}

			<Agreement>
				<div className="box">
					[웹심포지움 이용을 위한 개인정보 수집·이용 및 마케팅 활용 동의서]
					<br />
					<br />
					한국화이자제약 주식회사(이하 ‘회사’)는 [Pfizer 엘리퀴스 웹심포] (이하‘본 웹심포지움’) 을 진행할 예정입니다. <br />
					회사는 본 웹심포지움의 참가 안내 및 관련 서비스의 원활한 진행을 위하여 다음과 같이 가입자의 개인정보를 수집·이용하고 마케팅 목적으로 활용합니다. 아래 내용을 자세히 읽으신
					후 동의 여부를 결정하여 주십시오.
					<br />
					<br />
					1. (필수) 개인정보 수집·이용 동의 <br />
					<table>
						<tr>
							<th>수집하는 개인정보 항목</th>
							<td>성명, 직종, 병원명, 병원 주소, 진료 과목, 전화번호(근무지, 휴대폰), 전자우편주소 본 웹심포지움 사이트의 이용내역</td>
						</tr>
						<tr>
							<th>수집·이용 목적</th>
							<td>
								본 웹심포지움의 참가 및 관련 문의사항 안내
								<br />
								본 웹심포지움을 통한 서비스 제공
								<br />
								본 웹심포지움 참석 여부 확인 및 증빙
								<br />
								웹사이트 이용에 따른 민원사항의 처리 및 고지사항의 전달, 문의, 민원 및 답변 처리, 분쟁 대응, 제품에 대한 불만처리 기록 보관 및 부작용 보고, 회원의 서비스 이용 현황
								분석, 웹사이트 개선 및 사이트 사용 추세 파악
							</td>
						</tr>
						<tr>
							<th>이용 및 보유 기간</th>
							<td>
								<u>
									회사는 관계 법령의 규정에 따라 귀하의 개인정보를 계속 보존할 의무가 있는 경우가 아닌 한, 본 웹심포지움 당일이 속한 회계연도 종료 후 5년까지 귀하의 개인정보를 보유
									및 이용합니다.
								</u>
							</td>
						</tr>
					</table>
					<br />
					귀하께서는 개인정보의 수집·이용을 거부할 수 있으며 동의한 이후에도 언제든지 이를 철회할 수 있습니다. 다만, 이러한 개인정보 수집·이용에 동의하지 않을 경우 본 웹심포지움
					참가에 어려움이 발생할 수 있습니다. 위와 같이 개인정보를 수집·이용 하는데 동의하십니까?
				</div>
			</Agreement>

			<HRDash />
			<div style={{ textAlign: "center" }}>
				<label>
					<input type="radio" name="agree" value="Y" onChange={onSetItem} /> 개인정보 수집 및 이용에 동의함
				</label>
				<label style={{ marginLeft: "1rem" }}>
					<input type="radio" name="agree" value="N" onChange={onSetItem} />
					동의안함
				</label>
			</div>
			<HRDash />

			<Agreement style={{ marginTop: "10px" }}>
				<div className="box">
					2. (선택) 제3자 제공에 대한 동의
					<br />
					웹심포지엄(‘본 심포지엄’) 참석과 관련하여 귀하의 개인정보는 다음과 같이 제3자에게 제공됩니다. <br />
					<ul>
						<li>
							개인정보를 제공 받는자: <u>한국BMS제약(대표번호: 02-3404-1300 홈페이지: bms.com/kr)</u>
						</li>
						<li>
							개인정보 이용 목적: <u>본 심포지엄 참석 확인 및 통계적 산출</u>
						</li>
						<li>제공하는 개인정보의 항목: 성명, 병원명, 병원주소, 병원전화번호, 귀하의 본 행사 Webinar 관련 시청 시간 및 종료 시간</li>
						<li>
							개인정보 제공 받는자의 보유 및 이용 기간:{" "}
							<u>관계 법령의 규정에 따라 귀하의 개인정보를 계속 보존할 의무가 있는 경우가 아닌 한, 본 행사 종료 후 1년까지 귀하의 개인정보를 보유 및 이용합니다.</u>
						</li>
					</ul>
					<br />
					귀하는 동의를 거부할 권리가 있고 동의한 이후에도 언제든지 이를 철회할 수 있습니다. 다만 동의하지 않으시더라도 본 심포지엄 참석에는 제한이 없습니다.
					<br />
					위와 같이 개인정보 제3자 제공에 대해 동의하십니까?
				</div>
			</Agreement>

			<HRDash />
			<div style={{ textAlign: "center" }}>
				<label>
					<input type="radio" name="agree2" value="Y" onChange={onSetItem} /> 제3자 제공에 동의함
				</label>
				<label style={{ marginLeft: "1rem" }}>
					<input type="radio" name="agree2" value="N" onChange={onSetItem} />
					동의안함
				</label>
			</div>
			<HRDash />

			<Agreement style={{ marginTop: "10px" }}>
				<div className="box">
					3. (선택) 마케팅 활용 개인정보 수집·이용 동의
					<br />
					<table>
						<tr>
							<th>수집·이용 목적</th>
							<th>보유 기간</th>
						</tr>
						<tr>
							<td>
								<u>화이자 디테일링 서비스 (이하 ‘Pfizer V-rep’) 소개</u>
							</td>
							<td>
								<u>웹 심포지움이 끝난 후 6개월까지 혹은 마케팅 활용 동의 철회 시까지 </u>
							</td>
						</tr>
					</table>
					<br />
					마케팅 활용에 대한 동의를 거부하시는 경우에도 본 웹심포지움에 참여하실 수 있으나, Pfizer V-rep 소개 및 화상 디테일링을 통하여 엘리퀴스 관련 정보를 제공 받는데 제한이
					있습니다.
					<br />
					위와 같이 마케팅 활용에 대한 개인정보를 수집·이용 하는데 동의하십니까?
				</div>
			</Agreement>

			<HRDash />
			<div style={{ textAlign: "center" }}>
				<label>
					<input type="radio" name="agree3" value="Y" onChange={onSetItem} /> 마케팅 활용 개인정보 수집·이용에 동의함
				</label>
				<label style={{ marginLeft: "1rem" }}>
					<input type="radio" name="agree3" value="N" onChange={onSetItem} />
					동의안함
				</label>
			</div>
			<div style={{ fontSize: "0.8rem", fontWeight: "normal", marginTop: "0.8rem", lineHeight: "1rem" }}>
				상단에 게시된 회사의 개인정보수집 및 이용에 관한 설명을 모두 이해하였고, 이에 동의하시면 "동의함"에 체크하여 주시기 바랍니다.
			</div>
			<HRDash />
			<ButtonFormGroup>
				<ButtonForm primary title="확인" type="SUBMIT" onClick={onSave} />
				<ButtonForm title="취소" type="RESET" onClick={onBack} />
			</ButtonFormGroup>
		</>
	);
};

const Agreement = styled.div`
	line-height: 30px;
	font-weight: normal;
	font-size: 0.8rem;

	table {
		width: 100%;
		border-spacing: 0px;
		th {
			text-align: center;
			vertical-align: middle;
		}
		th,
		td {
			border: 1px solid #777777;
			font-size: 0.7rem;
			padding: 5px;
		}
	}

	ul {
		list-style: unset;
		margin: 0 1rem;
		padding: 1rem;
	}
`;

export const CustomRegCheckView = (props) => {
	const { code, history } = props;
	const { cfg } = useContext(AppContext);
	const { event } = cfg;
	const { cr } = event;

	const [item, setItem] = useState({});

	const pointRef = useRef(null);
	const scrollToBottom = () => {
		pointRef?.current?.scrollIntoView({ behavior: "smooth" });
	};
	useEffect(scrollToBottom, []);

	const onSetItem = (e) => {
		const target = e.target;
		setItem((o) => {
			return { ...o, [target.name]: target.value };
		});
	};

	const onBack = () => {
		history?.push("/");
	};

	const onSave = () => {
		if (!item.name) {
			swal("성명을 입력하세요.");
			return false;
		}
		if (event?.loginType === "EMAIL" && !item.email) {
			swal("이메일을 입력하세요.");
			return false;
		}
		if (event?.loginType === "MOBILE" && !item.mobile) {
			swal("휴대폰을 입력하세요.");
			return false;
		}

		// if (event?.useLoginPassword === "Y" && !item.passwd) {
		// 	swal("비밀번호를 입력하세요.");
		// 	return false;
		// }

		const data = { ...item, siteIdx: cfg.idx, siteEventIdx: cfg.event?.idx };

		_API
			.get({
				path: "/v1/site/event/registration/check",
				data: data,
			})
			.then((res) => {
				if (res.error === 0) {
					_U.setSession("_REGISTRATION_CHECK", res.data);
					history?.push("/registration/view");
				} else {
					swal(res.message);
				}
			});
	};

	useEffect(() => {
		_U.postMessage("LOCATION-CHANGED");
	}, []);

	return (
		<Container>
			<Wrapper ref={pointRef}>
				<H2>사전등록 조회</H2>
				<InputForm title="성명" name="name" value={item.name || ""} onChange={onSetItem} placeholder="사전등록시 입력한 성명을 입력하세요." />
				{event?.loginType === "EMAIL" && (
					<InputForm title="이메일" name="email" value={item.email || ""} onChange={onSetItem} placeholder="사전등록시 입력한 이메일을 입력하세요." />
				)}
				{event?.loginType === "MOBILE" && (
					<InputForm title="휴대전화번호" name="mobile" value={item.mobile || ""} onChange={onSetItem} placeholder="사전등록시 입력한 휴대전화번호를 입력하세요." />
				)}
				{/* {event?.useLoginPassword === "Y" && (
					<>
						<InputForm title="비밀번호" name="passwd" type="password" value={item.passwd} onChange={onSetItem} placeholder="사전등록시 입력한 비밀번호를 입력해 주세요" />
					</>
				)} */}
				<HRDash />
				<ButtonFormGroup>
					<ButtonForm primary title="확인" type="SUBMIT" onClick={onSave} />
					<ButtonForm title="취소" type="RESET" onClick={onBack} />
				</ButtonFormGroup>
			</Wrapper>
		</Container>
	);
};

export const CustomRegDetailView = (props) => {
	const { code, history } = props;
	const { cfg } = useContext(AppContext);

	const { event } = cfg;
	const { cr } = event;

	const [idx, setIdx] = useState(null);
	const [item, setItem] = useState({});

	const sessionCode = "_REGISTRATION_CHECK";

	const pointRef = useRef(null);
	const scrollToBottom = () => {
		pointRef?.current?.scrollIntoView({ behavior: "smooth" });
	};
	useEffect(scrollToBottom, []);

	useEffect(() => {
		const session = _U.getSession(sessionCode);
		if (!session) {
			onBack();
			return;
		}

		if (session.code !== sessionCode || !session || !session.idx) {
			onBack();
			return;
		}

		setIdx(session.idx);
	}, []);

	const loadData = () => {
		if (idx) {
			const data = { idx: idx, siteIdx: cfg.idx, siteEventIdx: cfg.event?.idx };

			_API
				.get({
					path: "/v1/site/event/registration/item",
					data: data,
				})
				.then((res) => {
					if (res.error === 0) {
						setItem(res.data);
					} else {
						swal(res.message);
					}
				});
		}
	};

	useEffect(() => {
		_U.postMessage("LOCATION-CHANGED");

		loadData();
	}, [idx]);

	const onBack = () => {
		history?.push("/registration/check");
	};

	return (
		<Container>
			<Wrapper ref={pointRef}>
				<H2>사전등록 내역</H2>
				{cr?.useName === "Y" && <InputViewForm title={cr?.nameText || "성명"} value={item?.name} />}
				{cr?.useEmail === "Y" && <InputViewForm title={cr?.emailText || "이메일"} value={item?.email} />}
				{cr?.useMobile === "Y" && <InputViewForm title={cr?.mobileText || "휴대전화번호"} value={item?.mobile} />}
				{cr?.useCompany === "Y" && <InputViewForm title={cr?.companyText || "소속"} value={item?.company} />}
				{cr?.useDepartment === "Y" && <InputViewForm title={cr?.departmentText || "부서"} value={item?.department} />}
				{cr?.usePosition === "Y" && <InputViewForm title={cr?.positionText || "직책"} value={item?.position} />}
				{cr?.useManager === "Y" && <InputViewForm title={cr?.managerText || "담당자"} value={item?.manager} />}
				{cr?.usePath === "Y" && <InputViewForm title={cr?.pathText || "등록경로"} value={item?.path === "기타" ? item?.pathText : item?.path} />}
				{cr?.useTeamWare === "Y" && (
					<>
						<InputViewForm title={cr?.teamWareText || "팀복사이즈"} value={item?.teamWareSize} />
						{item?.teamWareColor && <InputViewForm title={"색상"} value={item?.teamWareColor} />}
					</>
				)}

				<HRDash />
				<ButtonFormGroup>
					<ButtonForm
						title="수정"
						type="SUBMIT"
						onClick={() => {
							_U.setSession("_REGISTRATION_MODIFY", { code: "_REGISTRATION_MODIFY", idx: idx });
							history?.push("/registration/modify");
						}}
						primary
					/>
					<ButtonForm title="취소" type="RESET" onClick={onBack} />
				</ButtonFormGroup>
			</Wrapper>
		</Container>
	);
};

const Wrapper = styled.div`
	width: 70%;
	margin: 1rem auto;
	padding: 1rem;
	background: #ffffff;
	border-radius: 0.5rem;

	@media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
		width: 100%;
		margin: 0rem;
		padding: 0rem;
		border-radius: none;
	}
`;
