import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import Modal from "react-modal";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";
import { _API, _U } from "../../../modules/utils";

import { Content } from "./Components";
import BoothViewer from "../../../components/UI/Booth";
import Video from "../../../components/UI/Video";

export default (props) => {
    const { cfg } = useContext(AppContext);

    const { idx: siteIdx } = cfg;
    const { idx: siteEventIdx } = cfg.event;

    const [booth, setBooth] = useState([]);

    const loadData = () => {
        _API.get({
            path: "/v1/site/event/booth",
            data: {
                siteIdx,
                siteEventIdx,
            },
        })
            .then((res) => res.data)
            .then((data) => {
                setBooth(data);
            });
    };

    useEffect(() => loadData(), []);

    return (
        <Fragment>
            <Content code="booth" />
            {booth.length > 1 && (
                <BoothList>
                    {booth.map((item, i) => {
                        const content = JSON.parse(item.content);
                        const bg = content.bg["16x9"];
                        return (
                            <BoothItem key={i} bg={bg}>
                                <Link to={`/booth/${item.code}`}>
                                    <span>{item.title}</span>
                                </Link>
                            </BoothItem>
                        );
                    })}
                </BoothList>
            )}
            {booth.length === 1 && <Booth code={booth[0].code} />}
        </Fragment>
    );
};

const BoothList = styled.div`
    margin: 1rem auto 0;

    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    gap: 0.5rem;
`;
const BoothItem = styled.div`
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    border: #cccccc solid 1px;
    border-radius: 0.5rem;

    ${(props) =>
        props.bg &&
        `
        background-image: url(${props.bg});
        background-repeat: no-repeat;
        background-size:cover;
        `}

    a {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        color: #ffffff;
        line-height: 100%;
    }
`;

export const Booth = (props) => {
    const code = props.code || props.match?.params?.code;
    const { cfg } = useContext(AppContext);

    const { idx: siteIdx } = cfg;
    const { idx: siteEventIdx } = cfg.event;

    const [booth, setBooth] = useState(null);
    const [content, setContent] = useState(null);
    const [imageIndex, setImageIndex] = useState(0);
    const [imageShow, setImageShow] = useState(false);
    const [isVodUrl, setIsVodUrl] = useState(false);

    const loadData = () => {
        _API.get({
            path: "/v1/site/event/booth",
            data: {
                siteIdx,
                siteEventIdx,
                code,
            },
        })
            .then((res) => res.data)
            .then((data) => {
                setBooth(data);
                if (data?.vodUrl) {
                    setIsVodUrl(true);
                }
            });
    };

    const handleBoothClick = (el) => {
        if (!el || !el.cid) {
            return;
        }
        _API.get({
            path: "/v1/site/event/booth/content",
            data: {
                siteIdx,
                siteEventIdx,
                siteEventBootIdx: booth.idx,
                idx: el.cid,
            },
        })
            .then((res) => res.data)
            .then((data) => {
                setContent(data);
                if (data.type === "IMAGE") {
                    setImageShow(true);
                }
                if (data.type === "LINK") {
                    window.open(data.content);
                }
            });
    };

    const handleClearContent = () => {
        setContent(null);
    };

    useEffect(() => loadData(), [code]);

    useEffect(() => {
        if (content?.type === "IMAGE" && content?.content) {
            const tmpContent = JSON.parse(content?.content);
            const tmpContentList = tmpContent.list;
            tmpContentList.sort(function (a, b) {
                return parseInt(a.sort) < parseInt(b.sort) ? -1 : parseInt(a.sort) > parseInt(b.sort) ? 1 : 0;
            });

            setContent({
                ...content,
                content: JSON.stringify({ list: tmpContentList }),
            });
        }
    }, [content?.content]);

    if (!booth) {
        return null;
    }

    return (
        <Fragment>
            {isVodUrl && (
                <Modal isOpen={isVodUrl} style={isMobile ? basicModalStyles.mobile : basicModalStyles.pc}>
                    <CloseButton
                        className="btn btn-default"
                        onClick={() => {
                            setIsVodUrl(false);
                        }}
                    >
                        <i className="fa fa-remove"></i>
                    </CloseButton>
                    <Video url={booth?.vodUrl} autoPlay={true} loop={true} controls={false} />
                </Modal>
            )}
            <BoothViewer content={booth.content} onClick={handleBoothClick} />
            {content && (
                <Fragment>
                    {content.type === "IMAGE" && (
                        <Fragment>
                            {imageShow && (
                                <Lightbox
                                    reactModalStyle={{ overlay: { zIndex: 10000 } }}
                                    imageTitle={content.title}
                                    mainSrc={JSON.parse(content.content).list[imageIndex].url}
                                    nextSrc={JSON.parse(content.content).list[[imageIndex + 1] % JSON.parse(content.content).list.length].url}
                                    prevSrc={JSON.parse(content.content).list[(imageIndex + JSON.parse(content.content).list.length - 1) % JSON.parse(content.content).list.length].url}
                                    onCloseRequest={() => {
                                        setImageIndex(0);
                                        setImageShow(false);
                                    }}
                                    onMovePrevRequest={() => setImageIndex((imageIndex + JSON.parse(content.content).list.length - 1) % JSON.parse(content.content).list.length)}
                                    onMoveNextRequest={() => setImageIndex((imageIndex + 1) % JSON.parse(content.content).list.length)}
                                    animationDisabled={false}
                                    animationDuration={1000}
                                />
                            )}
                        </Fragment>
                    )}

                    {(content.type === "HTML" || content.type === "VIDEO") && (
                        <ContentContainer>
                            <ContentWrapper>
                                <ContentTitle>
                                    {content.title}
                                    <span onClick={handleClearContent}>
                                        <i className="fa fa-times"></i>
                                    </span>
                                </ContentTitle>
                                {content.type === "HTML" && <ContentBooth dangerouslySetInnerHTML={{ __html: content.content }} />}
                                {content.type === "VIDEO" && (
                                    <ContentBooth>
                                        <Video url={content.content} />
                                    </ContentBooth>
                                )}
                            </ContentWrapper>
                        </ContentContainer>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

const CloseButton = styled.button`
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 9999;
    color: #000;
    font-size: 1.5rem;
    cursor: pointer;
    background: #fff;
    line-height: unset;
    @media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
        font-size: 1rem;
    }
`;

const basicModalStyles = {
    mobile: {
        overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
        content: {
            top: "12rem",
            left: "1rem",
            right: "1rem",
            bottom: "12rem",
            borderRadius: "0",
            paddingBottom: "0",
            border: "0",
            padding: "0",
            backgroundColor: "rgba(0, 0, 0, 0)",
        },
    },
    pc: {
        overlay: { zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.75)" },
        content: {
            top: "5rem",
            left: "15%",
            right: "15%",
            bottom: "5rem",
            borderRadius: "0",
            paddingBottom: "0",
            border: "0",
            padding: "0",
            backgroundColor: "rgba(0, 0, 0, 0)",
            overflow: "hidden",
        },
    },
};

const ContentContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.5rem;
    background: rgba(0, 0, 0, 0.7);
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const ContentWrapper = styled.div`
    width: ${(props) => props.theme.basic.width}px;
    padding: 1rem;
    background: #ffffff;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
`;
const ContentTitle = styled.h3`
    margin: 0 0 1rem;
    font-size: 1.2rem;
    color: #000000;
    span {
        cursor: pointer;
        float: right;
        clear: right;
    }
`;
const ContentBooth = styled.div`
    flex: 1;
    width: 100%;
    min-height: 40vh;
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: auto;

    .image img {
        max-width: 100%;
    }
`;
