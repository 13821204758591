import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import moment from "moment";
import cx from "classnames";
import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";

import { _CFG } from "../../../modules/config";
import { _U, _CK } from "../../../modules/utils";

import Video from "../../../components/UI/Video";

import { Container, Wrapper, HeadWrapper, NavWrapper, CopyWrapper, Content, WebinarSessionList } from "./Components";
import { WebinarWrapper, WebinarVisualWrapper, WebinarAgendaWrapper, WebinarContent } from "./Components";

import Login from "./Components.Login";
import Registration, { RegView, RegCheckView, RegDetailView } from "./Registration";

import { default as AgendaView } from "./Agenda";
import { default as LocationView } from "./Location";
import { default as FeedbackView } from "./Feedback";
import { default as SessionView } from "./Session";
import { default as BoothView, Booth as BoothDetailView } from "./Booth";

import Socket from "../../../components/UI/Socket";

export default (props) => {
    const { cfg, auth } = useContext(AppContext);
    const { cfUrl, event } = cfg;

    const hasMenu = event?.menu?.length > 0;
    const hasLogo = !(!event?.uiLogoPcFilePath || !event?.uiLogoMobileFilePath);

    const teaser = event?.uiTeaserUrl;
    const afterUrl = event?.uiAfterUrl;
    const pc = event?.uiVisualPcFilePath ? `${cfUrl}${event?.uiVisualPcFilePath}` : "";
    const mobile = event?.uiVisualMobileFilePath ? `${cfUrl}${event?.uiVisualMobileFilePath}` : "";
    const hasVisual = (pc && mobile) || teaser;
    const isLogin = event?.useLogin === "Y" ? _CK.isLogin() : true;

    const pcAgenda = event?.uiAgendaPcFilePath ? `${cfUrl}${event?.uiAgendaPcFilePath}` : "";
    const mobileAgenda = event?.uiAgendaMobileFilePath ? `${cfUrl}${event?.uiAgendaMobileFilePath}` : "";
    const hasAgenda = pcAgenda && mobileAgenda;

    const isAfterUrl = afterUrl && isLogin; // && moment(event.eventEndDate, "YYYY-MM-DD HH:mm:ss") < moment()
    const [selectVodTime, setSelectVodTime] = useState(null);

    useEffect(() => {
        if (event) {
            setBackground(event, cfg.cfUrl);
        }
        return () => {
            clearBackground();
        };
    }, []);

    const location = event?.locations.length > 0 ? event.locations[0] : null;
    const nowStream = location ? location?.streams[0] : null;
    const isShowSession = isLogin && location;
    const isShowVisual = !isShowSession && hasVisual;

    const hlsConfig = {
        debug: _CFG.isLocal(),
        autoStartLoad: true,
        startPosition: -1,
    };

    return (
        <Router>
            <Container {...{ hasMenu, hasLogo }}>
                <Header />
                <Navigation />
                <WebinarWrapper>
                    {isShowVisual && !isAfterUrl && (
                        <WebinarVisualWrapper img={{ pc, mobile }}>
                            {teaser ? (
                                teaser.indexOf("m3u8") !== 0 ? (
                                    <Video url={teaser} autoPlay={true} poster={pc} loop={true} />
                                ) : (
                                    <iframe src={teaser} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
                                )
                            ) : (
                                <>
                                    <img alt="" />
                                </>
                            )}
                        </WebinarVisualWrapper>
                    )}

                    {!isAfterUrl && isLogin && nowStream && nowStream.streamPlayUrl && (
                        <WebinarVisualWrapper img={{ pc, mobile }}>
                            {nowStream.streamPlayUrl.indexOf("m3u8") !== -1 ? (
                                <Fragment>
                                    <Video url={nowStream.streamPlayUrl} poster={nowStream.posterImage} autoPlay={true} isLive />
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <iframe src={nowStream.streamPlayUrl} poster={nowStream.posterImage} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
                                </Fragment>
                            )}
                        </WebinarVisualWrapper>
                    )}

                    {isAfterUrl && (
                        <>
                            <WebinarVisualWrapper>
                                <Video url={afterUrl} autoPlay={true} poster={pc} loop={true} selectVodTime={selectVodTime} />
                            </WebinarVisualWrapper>
                            <WebinarSessionList
                                location={location}
                                onClick={(time) => {
                                    setSelectVodTime(time);
                                }}
                                setSelectVodTime={setSelectVodTime}
                                selectVodTime={selectVodTime}
                            />
                        </>
                    )}

                    {isShowSession && (
                        <SessionView
                            location={location.idx}
                            streams={location?.streams}
                            nowStream={nowStream}
                            onSetNowStream={(stream) => {
                                _CK.set("stream", stream);
                                setTimeout(() => {
                                    window.location.reload();
                                }, 100);
                            }}
                        />
                    )}

                    <WebinarContent className="wrapper">
                        <WebinarContent className="content left">
                            {event?.useLogin === "Y" && <Login />}
                            {event?.usePreReg === "Y" && <Registration />}
                        </WebinarContent>
                        <WebinarContent className="content right">
                            <Content code="home" />
                        </WebinarContent>
                    </WebinarContent>
                </WebinarWrapper>
                <Switch>
                    <Route path="/agenda" component={AgendaView} />
                    <Route path="/location" component={LocationView} />
                    <Route path="/survey" component={FeedbackView} />
                    <Route path="/feedback" component={FeedbackView} />
                    <Route path="/registration/check" component={RegCheckView} />
                    <Route path="/registration/view" component={RegDetailView} />
                    <Route path="/registration/modify" component={RegView} />
                    <Route path="/registration" component={RegView} />
                    <Route path="/booth" exact component={BoothView} />
                    <Route path="/booth/:code" component={BoothDetailView} />
                    <Route path="/:code" component={ContentView} />
                    <Redirect path="*" to="/" />
                </Switch>
                {hasAgenda && (
                    <WebinarAgendaWrapper img={{ pc: pcAgenda, mobile: mobileAgenda }}>
                        <img alt="" />
                    </WebinarAgendaWrapper>
                )}
                <Copyright />
                {(auth || cfg.event?.useLogin !== "Y") && <Socket />}
            </Container>
        </Router>
    );
};

const ContentView = (props) => {
    const { code } = props.match.params;
    return (
        <Wrapper>
            <Content code={code} />
        </Wrapper>
    );
};

const Header = (props) => {
    const { cfg } = useContext(AppContext);
    const { cfUrl, event } = cfg;

    const bgPc = event?.uiLogoPcFilePath ? `${cfUrl}${event?.uiLogoPcFilePath}` : "";
    const bgMobile = event?.uiLogoMobileFilePath ? `${cfUrl}${event?.uiLogoMobileFilePath}` : "";
    if (!bgPc || !bgMobile) {
        return null;
    }

    return (
        <HeadWrapper bg={{ pc: bgPc, mobile: bgMobile }}>
            <Link to="/"></Link>
        </HeadWrapper>
    );
};

const Navigation = (props) => {
    const { cfg } = useContext(AppContext);
    const { event } = cfg;

    const [menu, setMenu] = useState("");

    const activeMenu = () => {
        const { location } = window;
        if (!location) {
            return;
        }
        const { hash } = location;
        if (!hash) {
            return;
        }
        setMenu(hash.replace("#/", ""));
    };

    const onReceivedMessage = (e) => {
        _U.parseMessage(e).then((data) => {
            switch (data.code) {
                case "LOCATION-CHANGED":
                    activeMenu();
                    break;
            }
        });
    };

    useEffect(() => {
        window.addEventListener("message", onReceivedMessage);
        return () => {
            window.removeEventListener("message", onReceivedMessage);
        };
    }, [location]);

    if (event?.menu?.length === 0) {
        return null;
    }

    return (
        <NavWrapper bg={event?.uiBgColor} font={event?.uiFontColor}>
            <ul>
                <li className="home">
                    <Link to="/" className={cx({ active: !menu })}>
                        HOME
                    </Link>
                </li>
                {event?.menu?.map((item, i) => (
                    <li key={i}>
                        <Link to={`/${item.value}`} className={cx({ active: item.value === menu })}>
                            {item.label}
                        </Link>
                    </li>
                ))}
            </ul>
        </NavWrapper>
    );
};

const Copyright = (props) => {
    const { cfg } = useContext(AppContext);
    const { cfUrl, event } = cfg;

    const pc = event?.uiCopyPcFilePath ? `${cfUrl}${event?.uiCopyPcFilePath}` : "";
    const mobile = event?.uiCopyMobileFilePath ? `${cfUrl}${event?.uiCopyMobileFilePath}` : "";
    if (!pc || !mobile) {
        return null;
    }

    return (
        <CopyWrapper img={{ pc, mobile }}>
            <img alt="" />
        </CopyWrapper>
    );
};

/**** Others *****************************************/

const setBackground = (e, url) => {
    const b = $("body");
    if (e.uiBgColor) {
        b.css("background-color", e.uiBgColor);
    }
    if (isMobile) {
        if (e.uiBgMobileFilePath) {
            b.css("background-image", `url(${url}${e.uiBgMobileFilePath})`);
            b.css("background-repeat", "no-repeat");
            b.css("background-size", "cover");
        }
    } else {
        if (e.uiBgPcFilePath) {
            b.css("background-image", `url(${url}${e.uiBgPcFilePath})`);
            b.css("background-repeat", "no-repeat");
            b.css("background-size", "cover");
        }
    }
};
const clearBackground = () => {
    $("body").css("background", "#ffffff");
    $("body").css("background-image", "none");
};
