import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import moment from "moment";
import cx from "classnames";
import { isMobile } from "react-device-detect";

import { AppContext } from "../../../components/AppProvider";

import { _CFG } from "../../../modules/config";
import { _U, _CK } from "../../../modules/utils";

import Video from "../../../components/UI/Video";

import { Container, Wrapper, HeadWrapper, NavWrapper, CopyWrapper, Content, CloseButton, HRDash, WebinarSessionList } from "./Components";
import { WebinarWrapper, WebinarVisualWrapper, WebinarAgendaWrapper, WebinarContent, TemplateLeftWrapper, TemplateRightWrapper } from "./Components";
import { ContentImageModal, ContentModal, RegistrationModal, RegistrationCheckModal, RegistrationViewModal } from "./Modal";

import Login from "./Components.Login";
import Registration from "./Registration";
import { default as SessionView } from "./Session";
import { Booth as BoothDetailView } from "./Booth";

import Socket from "../../../components/UI/Socket";

export default (props) => {
	const { cfg, auth } = useContext(AppContext);
	const { cfUrl, event } = cfg;
	const { hash } = window.location;

	const hasMenu = event?.menu?.length > 0;
	const hasLogo = !(!event?.uiLogoPcFilePath || !event?.uiLogoMobileFilePath);

	const teaser = event?.uiTeaserUrl;
	const afterUrl = event?.uiAfterUrl;
	const pc = event?.uiVisualPcFilePath ? `${cfUrl}${event?.uiVisualPcFilePath}` : "";
	const mobile = event?.uiVisualMobileFilePath ? `${cfUrl}${event?.uiVisualMobileFilePath}` : "";
	const hasVisual = (pc && mobile) || teaser || afterUrl;
	const isLogin = event?.useLogin === "Y" ? _CK.isLogin() : true;

	const pcAgenda = event?.uiAgendaPcFilePath ? `${cfUrl}${event?.uiAgendaPcFilePath}` : "";
	const mobileAgenda = event?.uiAgendaMobileFilePath ? `${cfUrl}${event?.uiAgendaMobileFilePath}` : "";
	const hasAgenda = pcAgenda && mobileAgenda;

	const isAfterUrl = afterUrl && isLogin; // && moment(event.eventEndDate, "YYYY-MM-DD HH:mm:ss") < moment()

	const [isMenuView, setIsMenuView] = useState(null);
	const [menuLabel, setMenuLabel] = useState(null);
	const [selectVodTime, setSelectVodTime] = useState(null);

	const [hideHome, setHideHome] = useState(false);

	useEffect(() => {
		if (event) {
			setBackground(event, cfg.cfUrl);
		}
		return () => {
			clearBackground();
		};
	}, []);

	const location = event?.locations.length > 0 ? event.locations[0] : null;
	const nowStream = location ? location?.streams[0] : null;
	const isShowSession = isLogin && location && event.uiSessionBox === "Y";
	const isShowVisual = hasVisual; //!isShowSession &&
	const isStreamPage = hash === "#/streaming" && isLogin;

	const hlsConfig = {
		debug: _CFG.isLocal(),
		autoStartLoad: true,
		startPosition: -1,
	};

	const onRegistration = () => {
		setIsMenuView("registration");
	};

	const onRegistrationCheck = () => {
		//setIsRegistrationCheck(true);
		setIsMenuView("registrationCheck");
	};

	const setModal = (code, label) => {
		setIsMenuView(code);
		setMenuLabel(label);
	};

	const getModalView = (code) => {
		switch (code) {
			case "cv":
			case "agenda":
			case "introduce":
			case "faq":
				return <ContentImageModal isMenuView={isMenuView} setIsMenuView={setIsMenuView} menuLabel={menuLabel} />;
			case "registration":
				return <RegistrationModal isMenuView={isMenuView} setIsMenuView={setIsMenuView} />;
			case "registrationCheck":
				return <RegistrationCheckModal isMenuView={isMenuView} setIsMenuView={setIsMenuView} />;
			case "registrationView":
				return <RegistrationViewModal isMenuView={isMenuView} setIsMenuView={setIsMenuView} />;
			default:
				if (isMenuView) {
					return <ContentModal isMenuView={isMenuView} setIsMenuView={setIsMenuView} menuLabel={menuLabel} />;
				}
		}
	};

	const onReceivedMessage = (e) => {
		_U.parseMessage(e).then((data) => {
			switch (data.code) {
				case "HOME-AREA-HIDE":
					setHideHome(true);
					break;
			}
		});
	};

	useEffect(() => {
		window.addEventListener("message", onReceivedMessage);
		return () => {
			window.removeEventListener("message", onReceivedMessage);
		};
	}, []);

	return (
		<Router>
			<Container {...{ hasMenu, hasLogo }} isStreamPage={isStreamPage}>
				{(!isStreamPage || isMobile) && <Header />}
				{(!isStreamPage || isMobile) && <Navigation setModal={setModal} />}
				<WebinarWrapper isStreamPage={isStreamPage}>
					<TemplateLeftWrapper isStreamPage={isStreamPage}>
						{isShowVisual && !isAfterUrl && !isStreamPage && (
							<WebinarVisualWrapper img={{ pc, mobile }}>
								{teaser ? (
									teaser.indexOf("m3u8") !== 0 ? (
										<Video url={teaser} autoPlay={true} poster={pc} loop={true} />
									) : (
										<iframe src={teaser} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
									)
								) : (
									<>
										<img alt="" />
									</>
								)}
							</WebinarVisualWrapper>
						)}

						{!isStreamPage && isAfterUrl && (
							<>
								<Video url={afterUrl} autoPlay={true} poster={pc} loop={true} selectVodTime={selectVodTime} />
								<WebinarSessionList
									location={location}
									onClick={(time) => {
										setSelectVodTime(time);
									}}
									setSelectVodTime={setSelectVodTime}
									selectVodTime={selectVodTime}
								/>
							</>
						)}
						{isStreamPage && nowStream && nowStream.streamPlayUrl && (
							<WebinarVisualWrapper img={{ pc, mobile }}>
								{nowStream.streamPlayUrl.indexOf("m3u8") !== -1 ? (
									<Fragment>
										<Video url={nowStream.streamPlayUrl} poster={nowStream.posterImage} autoPlay={true} isLive />
									</Fragment>
								) : (
									<Fragment>
										<iframe src={nowStream.streamPlayUrl} poster={nowStream.posterImage} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
									</Fragment>
								)}
							</WebinarVisualWrapper>
						)}
					</TemplateLeftWrapper>

					<TemplateRightWrapper isStreamPage={isStreamPage}>
						<>
							{isShowVisual && isStreamPage && !isMobile && (
								<WebinarVisualWrapper img={{ pc, mobile }}>
									{teaser ? (
										teaser.indexOf("m3u8") !== 0 ? (
											<Video url={teaser} autoPlay={true} poster={pc} loop={true} />
										) : (
											<iframe src={teaser} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
										)
									) : (
										<img alt="" />
									)}
								</WebinarVisualWrapper>
							)}
							{isShowSession && (
								<SessionView
									isStreamPage={isStreamPage}
									location={location.idx}
									viewType={isStreamPage ? "" : "COUNTDOWN"}
									streams={location?.streams}
									nowStream={nowStream}
									setModal={setModal}
									onSetNowStream={(stream) => {
										_CK.set("stream", stream);
										setTimeout(() => {
											window.location.reload();
										}, 100);
									}}
								/>
							)}
						</>
					</TemplateRightWrapper>

					{(!isStreamPage || isMobile) && (
						<>
							<HRDash />
							<WebinarContent className="wrapper">
								<WebinarContent className="content left">
									{event?.useLogin === "Y" && <Login />}
									{event?.usePreReg === "Y" && <Registration onRegistration={onRegistration} onRegistrationCheck={onRegistrationCheck} />}
								</WebinarContent>
								{!hideHome && (
									<WebinarContent className="content right">
										<Content code="home" />
									</WebinarContent>
								)}
							</WebinarContent>
						</>
					)}
				</WebinarWrapper>
				<Switch>
					<Route path="/booth/:code" component={BoothDetailView} />
					<Route path="/:code" component={ContentView} />
					<Redirect path="*" to="/" />
				</Switch>
				{(!isStreamPage || isMobile) && hasAgenda && (
					<WebinarAgendaWrapper img={{ pc: pcAgenda, mobile: mobileAgenda }}>
						<img alt="" />
					</WebinarAgendaWrapper>
				)}

				{getModalView(isMenuView)}

				{(!isStreamPage || isMobile) && <Copyright />}

				{((cfg.event?.useLogin === "Y" && auth) || cfg.event?.useLogin === "N") && <Socket />}
			</Container>
		</Router>
	);
};

const ContentView = (props) => {
	let code = props.match?.params?.code;
	if (!code) {
		code = props.code;
	}

	const [isHide, setIsHide] = useState(false);

	if (isHide) {
		return "";
	}

	return (
		<Wrapper>
			<Content code={code} onIsHide={(isHide) => setIsHide(isHide)} />
		</Wrapper>
	);
};

const Header = (props) => {
	const { cfg } = useContext(AppContext);
	const { cfUrl, event } = cfg;

	const bgPc = event?.uiLogoPcFilePath ? `${cfUrl}${event?.uiLogoPcFilePath}` : "";
	const bgMobile = event?.uiLogoMobileFilePath ? `${cfUrl}${event?.uiLogoMobileFilePath}` : "";
	if (!bgPc || !bgMobile) {
		return null;
	}

	return (
		<HeadWrapper bg={{ pc: bgPc, mobile: bgMobile }}>
			<Link to="/"></Link>
		</HeadWrapper>
	);
};

const Navigation = (props) => {
	const { cfg } = useContext(AppContext);
	const { event } = cfg;
	const { setModal } = props;

	const [menu, setMenu] = useState("");

	const activeMenu = () => {
		const { location } = window;
		if (!location) {
			return;
		}
		const { hash } = location;
		if (!hash) {
			return;
		}
		setMenu(hash.replace("#/", ""));
	};

	const onReceivedMessage = (e) => {
		_U.parseMessage(e).then((data) => {
			switch (data.code) {
				case "LOCATION-CHANGED":
					activeMenu();
					break;
			}
		});
	};

	useEffect(() => {
		window.addEventListener("message", onReceivedMessage);
		return () => {
			window.removeEventListener("message", onReceivedMessage);
		};
	}, [location]);

	if (event?.menu?.length === 0) {
		return null;
	}
	return (
		<NavWrapper bg={event?.uiBgColor} font={event?.uiFontColor} isLogin={_CK.isLogin()}>
			<ul>
				{_CK.isLogin() && (
					<li className="home">
						<Link to="/" onClick={setModal.bind(this, null)} className={cx({ active: !menu })}>
							HOME
						</Link>
					</li>
				)}
				{event?.menu?.map((item, i) => (
					<Fragment key={i}>
						{(item.useLogin === "N" || _CK.isLogin()) && (
							<li>
								<Link to={`/`} onClick={setModal.bind(this, item.value, item.label)} className={cx({ active: item.value === menu })}>
									{item.label}
								</Link>
							</li>
						)}
					</Fragment>
				))}
			</ul>
		</NavWrapper>
	);
};

const Copyright = (props) => {
	const { cfg } = useContext(AppContext);
	const { cfUrl, event } = cfg;

	const pc = event?.uiCopyPcFilePath ? `${cfUrl}${event?.uiCopyPcFilePath}` : "";
	const mobile = event?.uiCopyMobileFilePath ? `${cfUrl}${event?.uiCopyMobileFilePath}` : "";
	if (!pc || !mobile) {
		return null;
	}

	return (
		<CopyWrapper img={{ pc, mobile }}>
			<img alt="" />
		</CopyWrapper>
	);
};

/**** Others *****************************************/

const setBackground = (e, url) => {
	const b = $("body");
	if (e.uiBgColor) {
		b.css("background-color", e.uiBgColor);
	}
	if (isMobile) {
		if (e.uiBgMobileFilePath) {
			b.css("background-image", `url(${url}${e.uiBgMobileFilePath})`);
			b.css("background-repeat", "no-repeat");
			b.css("background-size", "cover");
		}
	} else {
		if (e.uiBgPcFilePath) {
			b.css("background-image", `url(${url}${e.uiBgPcFilePath})`);
			b.css("background-repeat", "no-repeat");
			b.css("background-size", "cover");
		}
	}
};
const clearBackground = () => {
	$("body").css("background", "#ffffff");
	$("body").css("background-image", "none");
};
