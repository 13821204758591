import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import moment from "moment";
import cx from "classnames";
import { isMobile } from "react-device-detect";
import styled from "styled-components";

import { AppContext } from "../../components/AppProvider";

import { _CFG } from "../../modules/config";
import { _U, _CK } from "../../modules/utils";

import Video from "../../components/UI/Video";

export default (props) => {
    const query = new URLSearchParams(props.location.search);
    const url = query.get("url");

    return <Fragment>{url && <Video url={url} autoPlay={true} isLive />}</Fragment>;
};
