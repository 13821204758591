import React, { Fragment, useContext, useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import moment from "moment";
import cx from "classnames";
import { isMobile } from "react-device-detect";
import styled from "styled-components";

import { AppContext } from "../../components/AppProvider";

import { _CFG } from "../../modules/config";
import { _U, _CK } from "../../modules/utils";

export default (props) => {
	const { history } = props;
	const { cfg, auth, saveEventDate } = useContext(AppContext);
	const { cfUrl, events } = cfg;

	let introImage = !isMobile ? cfg?.introFileUrl : cfg?.introMobileFileUrl || cfg?.introFileUrl;

	useEffect(() => {
		$("body").css("background-color", cfg?.introColor || "#ffffff");
	}, [cfg?.introColor]);

	const onClickEvent = (e) => {
		// if (e.noUseEnter === "N" && moment() < moment(moment(`${e.eventDate}`, "YYYY-MM-DD").format("YYYY-MM-DD 00:00:00"))) {
		// 	swal("아직 행사일 전 입니다.");
		// 	return;
		// }

		saveEventDate(e.eventDate);
		window.open(`?eventDate=${e.eventDate}#/`);
	};

	const getImage = (e) => {
		// if (e.noUseEnter === "N" && moment() > moment(moment(`${e.eventDate}`, "YYYY-MM-DD").format("YYYY-MM-DD 23:59:59"))) {
		// 	return;
		// }

		if (!e.eventDate) {
			return;
		}

		if (moment() > moment(moment(`${e.eventDate}`, "YYYY-MM-DD").format("YYYY-MM-DD 23:59:59"))) {
			return;
		}

		if (moment().format("YYYY-MM-DD") == e.eventDate) {
			return <img src={e?.buttonOnFileUrl} onClick={() => onClickEvent(e)} />;
		} else {
			return <img src={e?.buttonOffFileUrl} onClick={() => onClickEvent(e)} />;
		}
	};

	return (
		<Wrapper color={cfg?.introColor}>
			<div className="visual">
				<img src={introImage} />
			</div>
			<ButtonWrapper position={cfg?.introButtonLocation}>
				{events?.map((button, index) => (
					<>{getImage(button) && <div key={index}>{getImage(button)}</div>}</>
				))}
			</ButtonWrapper>
			<IntroFooter color={cfg?.introFooterColor}>{cfg?.introFooter}</IntroFooter>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: 100vw;
	height: 100%;
	background-color: ${(props) => (props.color ? props.color : "#ffffff")};

	> div.visual {
		width: 1200px;
		margin: 0 auto;

		@media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
			width: 100%;
		}
	}
	> div.visual > img {
		width: 100%;
		text-align: center;
	}
`;

const ButtonWrapper = styled.div`
	text-align: center;
	width: 1200px;
	margin: 20px auto !important;
	flex-wrap: wrap;
	display: flex;

	justify-content: space-around;
	@media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
		display: block;
	}

	> div {
		flex: 0 30%;

		@media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
			display: block;
			padding: 0 1rem;
			margin-bottom: 0.5rem;
		}
	}

	> div > img {
		cursor: pointer;
		width: 100%;
	}

	@media only screen and (max-width: ${(props) => props.theme.responsive.minWidth}px) {
		width: 100%;
	}
`;

const IntroFooter = styled.div`
	margin: 20px auto !important;
	min-height: 50px;
	width: 100%;
	text-align: center;
	color: ${(props) => (props.color ? props.color : `#000000`)};
	display: flex;
	align-items: center;
	justify-content: center;
`;
