import React, { useContext, useEffect } from "react";
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import { AppContext } from "../components/AppProvider";
import { _CK } from "../modules/utils";

import Root from "./Root";
import {
    TplBasicViews,
    TplWebinarSingleViews,
    TplBoothOnlyViews,
    TplWebinarSingleLoginPasswordViews,
    TplWebinarSingleModalViews,
    TplWebinarLinksiumSingleViews,
    TplIntroViews,
    TblWebinarVmcSingleViews,
    TblWebinarMedigateSingleViews,
    StreamEmbed,
    TblWebinarKakaoSingleViews,
} from "./template";

export default (props) => {
    const { auth, cfg, eventDate } = useContext(AppContext);
    useEffect(() => {}, [auth]);

    if (cfg.useIntro === "Y" && !eventDate && window.location.hash !== "#/intro") {
        location.hash = "#/intro";
        window.location.reload();
        return "";
    } else {
        if (!window.location.hash.startsWith("#/embed") && window.location.hash !== "#/intro") {
            const { event } = cfg;

            if (event) {
                switch (event.uiTemplate) {
                    case "WEBINAR-SINGLE-ROOM":
                        return <TplWebinarSingleViews />;
                    case "WEBINAR-VMC-SINGLE-ROOM":
                        return <TblWebinarVmcSingleViews />;
                    case "WEBINAR-LINKSIUM-SINGLE-ROOM":
                        return <TplWebinarLinksiumSingleViews />;
                    case "WEBINAR-KAKAO-SINGLE-ROOM":
                        return <TblWebinarKakaoSingleViews />;
                    case "WEBINAR-LOGIN-PASSWORD":
                        return <TplWebinarSingleLoginPasswordViews />;
                    case "WEBINAR-MODAL-SIGNLE-ROOM":
                        return <TplWebinarSingleModalViews />;
                    case "WEBINAR-MEDIGATE-SINGLE-ROOM":
                        return <TblWebinarMedigateSingleViews />;
                    case "BOOTH-ONLY":
                        return <TplBoothOnlyViews />;
                    default:
                        // Basic & Others
                        return <TplBasicViews />;
                }
            }
        }
    }
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={Root} />
                <Route path="/intro" exact component={TplIntroViews} />
                <Route path="/embed" exact component={StreamEmbed} />
                <Redirect path="*" to="/" />
            </Switch>
        </Router>
    );
};
